<template>
  <div class="bp-star-rating">
    <Star
      :class="{
        'bp-star-rating--filled':
          starFillPercent(entry) == 100 && rating != null,
        'bp-star-rating--unfilled': rating == null
      }"
      :percentageFill="starFillPercent(entry)"
      v-for="entry in [1, 2, 3, 4, 5]"
      :key="entry"
      :uniqueId="`${uniqueId}${entry}`"
    />
  </div>
</template>

<script>
import Star from "./Star"

export default {
  props: ["rating", "uniqueId"],
  components: {
    Star
  },
  methods: {
    starFillPercent(starPosition) {
      if (this.rating == null) {
        return 0
      } else if (this.rating == 5) {
        return 100
      } else if (starPosition <= Math.trunc(this.rating)) {
        return 100
      } else if (starPosition > Math.trunc(this.rating) + 1) {
        return 0
      } else {
        return (this.rating % 1) * 100
      }
    }
  },
  computed: {}
}
</script>

<style lang="scss">
.bp-star-rating {
  svg {
    height: 19px;
    width: 19px;

    &:not(:last-child) {
      margin-right: 2px;
    }

    stop:nth-child(1) {
      stop-color: $bleach-lime;
    }
    stop:nth-child(2) {
      stop-color: $bleach-lime;
    }
    stop:nth-child(3) {
      stop-color: transparent;
    }
    stop:nth-child(4) {
      stop-color: transparent;
    }
  }

  svg.bp-star-rating--filled {
    stop:nth-child(-n + 4) {
      stop-color: $bleach-lime;
    }
  }

  svg.bp-star-rating--unfilled {
    stop:nth-child(-n + 4) {
      stop-color: $bleach-review-borders;
    }

    path {
      stroke: $bleach-review-borders;
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
  }
}
</style>
